import React from 'react'
import { Col, Container, Row, } from 'react-bootstrap';
import trouimg from '../assets/images/t1.jpg'
import trouimg1 from '../assets/images/t2.jpg'
import trouimg2 from '../assets/images/t3.jpg'
import trouimg3 from '../assets/images/t4.png'
import { Link } from 'react-router-dom';

export default function TourPackagesCat() {
  return (
    <div className='tour_packages2 cards py-5'>
      <Container>
        <Row className='justify-content-center mb-3'>
          <Col md="5">
            <div className='text-center mb-4'>
              <h2>Top Destinations</h2>
              <p>Discover, explore, and cherish unforgettable moments in top destinations worldwide.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="4" className='mb-4 col-6'>
            <Link to="" className="card_items">
              <div className='transition5'>
                <img src={trouimg} className="card__image" alt="" />
              </div>
              <div className="hm-content">
                <div className="hm_content_inn">
                  <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                  <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                </div>
                <Link to="#" className='btn_detail btn btn-outline-light'>View Details</Link>
              </div>
            </Link>
          </Col>
          <Col md="4" className='mb-4 col-6'>
            <Link to="" className="card_items">
              <div className='transition5'>
                <img src={trouimg2} className="card__image" alt="" />
              </div>
              <div className="hm-content">
                <div className="hm_content_inn">
                  <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                  <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                </div>
                <Link to="#" className='btn_detail btn btn-outline-light'>View Details</Link>
              </div>
            </Link>
          </Col>
          <Col md="4" className='mb-4 col-6'>
            <Link to="" className="card_items">
              <div className='transition5'>
                <img src={trouimg3} className="card__image" alt="" />
              </div>
              <div className="hm-content">
                <div className="hm_content_inn">
                  <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                  <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                </div>
                <Link to="#" className='btn_detail btn btn-outline-light'>View Details</Link>
              </div>
            </Link>
          </Col>
          <Col md="4" className='mb-4 col-6'>
            <Link to="" className="card_items">
              <div className='transition5'>
                <img src={trouimg3} className="card__image" alt="" />
              </div>
              <div className="hm-content">
                <div className="hm_content_inn">
                  <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                  <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                </div>
                <Link to="#" className='btn_detail btn btn-outline-light'>View Details</Link>
              </div>
            </Link>
          </Col>
          <Col md="4" className='mb-4 col-6'>
            <Link to="" className="card_items">
              <div className='transition5'>
                <img src={trouimg1} className="card__image" alt="" />
              </div>
              <div className="hm-content">
                <div className="hm_content_inn">
                  <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                  <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                </div>
                <Link to="#" className='btn_detail btn btn-outline-light'>View Details</Link>
              </div>
            </Link>
          </Col>
          <Col md="4" className='mb-4 col-6'>
            <Link to="" className="card_items">
              <div className='transition5'>
                <img src={trouimg} className="card__image" alt="" />
              </div>
              <div className="hm-content">
                <div className="hm_content_inn">
                  <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                  <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                </div>
                <Link to="#" className='btn_detail btn btn-outline-light'>View Details</Link>
              </div>
            </Link>
          </Col>
         
        </Row>
      </Container>
    </div>
  )
}