import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import facebook from '../assets/images/facebook.png';
import twitter from '../assets/images/twitter.png';
import linkedin from '../assets/images/linkedin.png';
import youtube from '../assets/images/youtube.png';
import instagram from '../assets/images/instagram.png';

export default function Footer() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div>
      <div className='footer_style'>
        <Container>
          <Row>
            <Col md="2" className='mb-4'>
              <h5 className='f_title'>Useful Links</h5>
              <ul>
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">About Us</Link></li>
                <li><Link to="#">Testimonials</Link></li>
                <li><Link to="#">Gallery</Link></li>
                <li><Link to="#">Video Gallery</Link></li>
                <li><Link to="#">Car Rentals</Link></li>
              </ul>
            </Col>
            <Col md="2" className='mb-4'>
              <h5 className='f_title'>Services Links</h5>
              <ul>
                <li><Link to="#">Vision & Mission</Link></li>
                <li><Link to="#">FAQs</Link></li>
                <li><Link to="#">Photo Gallery</Link></li>
                <li><Link to="#">Contact us</Link></li>
              </ul>
            </Col>
            <Col md="4" className='mb-4'>
              <h5 className='f_title'>Information</h5>
              <ul>
                <li><FontAwesomeIcon icon="fa-solid fa-phone-volume" /> +91 1234567890</li>
                <li><FontAwesomeIcon icon="fa-solid fa-envelope-open-text" /> companyname@gmail.com</li>
                <li><FontAwesomeIcon icon="fa-solid fa-signs-post" /> B 3/260, Shivala, Varanasi (UP) - 221001</li>
              </ul>
              <div className='mt-4 d-flex gap-2'>
                <Link to="#"><img src={facebook} alt='' /></Link>
                <Link to="#"><img src={youtube} alt='' /></Link>
                <Link to="#"><img src={twitter} alt='' /></Link>
                <Link to="#"><img src={linkedin} alt='' /></Link>
                <Link to="#"><img src={instagram} alt='' /></Link>
              </div>
            </Col>
            <Col md="4" className='mb-4'>
              <h5 className='f_title'>Subscribe</h5>
              <p>Subscribe Our Newsletter For Getting Quick Updates</p>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  {/* <Form.Label>Enter Your Email</Form.Label> */}
                  <Form.Control type="email" className='rounded-5' placeholder="Enter Your Email" />
                </Form.Group>
                <Button className='primary-btn3 ' type="submit">Subscribe</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className='justify-content-center py-2'>
          <Col className='col-auto me-lg-auto'>© 2024, Company name. All rights reserved.</Col>
          <Col className='col-auto'>Designed by <Link to="#" target='_blank'>Company name Technologies</Link></Col>
        </Row>
      </Container>
      {isVisible && (
        <div className="scroll-to-top">
          <button onClick={scrollToTop}><FontAwesomeIcon icon="fa-solid fa-angle-up" /></button>
        </div>
      )}
    </div>
  )
}