import React from 'react'
import Banner from '../common/Banner'
import TourPackages from '../common/TourPackages'
import AboutHome from '../common/AboutHome'
import TourPackagesCat from '../common/TourPackagesCat'

export default function Home() {
  return (
    <React.Fragment>
      <Banner />
      <TourPackages />
      <AboutHome />
      <TourPackagesCat/>
    </React.Fragment>
  )
}