import React from 'react'
import { Col, Container, Row, } from 'react-bootstrap';
import trouimg from '../assets/images/t1.jpg'
import trouimg1 from '../assets/images/t2.jpg'
import trouimg2 from '../assets/images/t3.jpg'
import trouimg3 from '../assets/images/t4.png'
import { Link } from 'react-router-dom';

export default function TourPackages() {
  return (
    <div className='tour_packages cards py-5'>
      <Container>
        <Row className='justify-content-center mb-3'>
          <Col md="5">
            <div className='text-center mb-4'>
              <h2>Recommended for you</h2>
              <p>Explore the Best Tours: Discover top-rated activities and excursions for an unforgettable travel experience.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="3" className='mb-4 col-6'>
            <Link to="" className="card_items rounded-2 h-100 h-100">
              <div className='card__image1'>
                <img src={trouimg} className="card__image rounded-2" alt="" />
              </div>
              <div className="card_items_inn">
                <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                <p>You're in control, with free cancellation and payment options to...</p>
                <div className="d-flex justify-content-between card_footer border-top pt-2">
                  <span><i className="bi bi-stopwatch"></i> 4 days</span>
                  <span className='text-danger'>From ₹2025</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col md="3" className='mb-4 col-6'>
            <Link to="" className="card_items rounded-2 h-100">
              <div className='card__image1'>
                <img src={trouimg1} className="card__image rounded-2" alt="" />
              </div>
              <div className="card_items_inn">
                <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                <p>You're in control, with free cancellation and payment options to...</p>
                <div className="d-flex justify-content-between card_footer border-top pt-2">
                  <span><i className="bi bi-stopwatch"></i> 4 days</span>
                  <span className='text-danger'>From ₹2025</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col md="3" className='mb-4 col-6'>
            <Link to="" className="card_items rounded-2 h-100">
              <div className='card__image1'>
                <img src={trouimg2} className="card__image rounded-2" alt="" />
              </div>
              <div className="card_items_inn">
                <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                <p>You're in control, with free cancellation and payment options to...</p>
                <div className="d-flex justify-content-between card_footer border-top pt-2">
                  <span><i className="bi bi-stopwatch"></i> 4 days</span>
                  <span className='text-danger'>From ₹2025</span>
                </div>
              </div>
            </Link>
          </Col>
          <Col md="3" className='mb-4 col-6'>
            <Link to="" className="card_items rounded-2 h-100">
              <div className='card__image1'>
                <img src={trouimg3} className="card__image rounded-2" alt="" />
              </div>
              <div className="card_items_inn">
                <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                <p>You're in control, with free cancellation and payment options to...</p>
                <div className="d-flex justify-content-between card_footer border-top pt-2">
                  <span><i className="bi bi-stopwatch"></i> 4 days</span>
                  <span className='text-danger'>From ₹2025</span>
                </div>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}