import React from 'react'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import trouimg from '../assets/images/t1.jpg'
import trouimg1 from '../assets/images/t2.jpg'
import trouimg2 from '../assets/images/t3.jpg'
import trouimg3 from '../assets/images/t4.png'

export default function Packages() {
  return (
    <React.Fragment>
          <div className='page_heaer'>
              <Container>
                  <h1 className='f_title'>Packages</h1>
                  <Breadcrumb>
                      <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                      <Breadcrumb.Item active>Packages</Breadcrumb.Item>
                  </Breadcrumb>
              </Container>
          </div>
          <div className='tour_packages2 py-4'>
              <Container>
                  <Row>
                      <Col md="4" className='mb-4 col-6'>
                          <Link to="/list" className="card_items">
                              <div className='transition5'>
                                  <img src={trouimg} className="card__image" alt="" />
                              </div>
                              <div className="hm-content">
                                  <div className="hm_content_inn">
                                      <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                      <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  </div>
                                  <Link to="/list" className='btn_detail btn btn-outline-light'>View Details</Link>
                              </div>
                          </Link>
                      </Col>
                      <Col md="4" className='mb-4 col-6'>
                          <Link to="/list" className="card_items">
                              <div className='transition5'>
                                  <img src={trouimg2} className="card__image" alt="" />
                              </div>
                              <div className="hm-content">
                                  <div className="hm_content_inn">
                                      <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                      <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  </div>
                                  <Link to="/list" className='btn_detail btn btn-outline-light'>View Details</Link>
                              </div>
                          </Link>
                      </Col>
                      <Col md="4" className='mb-4 col-6'>
                          <Link to="/list" className="card_items">
                              <div className='transition5'>
                                  <img src={trouimg3} className="card__image" alt="" />
                              </div>
                              <div className="hm-content">
                                  <div className="hm_content_inn">
                                      <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                      <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  </div>
                                  <Link to="/list" className='btn_detail btn btn-outline-light'>View Details</Link>
                              </div>
                          </Link>
                      </Col>
                      <Col md="4" className='mb-4 col-6'>
                          <Link to="/list" className="card_items">
                              <div className='transition5'>
                                  <img src={trouimg3} className="card__image" alt="" />
                              </div>
                              <div className="hm-content">
                                  <div className="hm_content_inn">
                                      <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                      <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  </div>
                                  <Link to="/list" className='btn_detail btn btn-outline-light'>View Details</Link>
                              </div>
                          </Link>
                      </Col>
                      <Col md="4" className='mb-4 col-6'>
                          <Link to="/list" className="card_items">
                              <div className='transition5'>
                                  <img src={trouimg1} className="card__image" alt="" />
                              </div>
                              <div className="hm-content">
                                  <div className="hm_content_inn">
                                      <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                      <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  </div>
                                  <Link to="/list" className='btn_detail btn btn-outline-light'>View Details</Link>
                              </div>
                          </Link>
                      </Col>
                      <Col md="4" className='mb-4 col-6'>
                          <Link to="/list" className="card_items">
                              <div className='transition5'>
                                  <img src={trouimg} className="card__image" alt="" />
                              </div>
                              <div className="hm-content">
                                  <div className="hm_content_inn">
                                      <span className='text-light'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                      <h4>Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  </div>
                                  <Link to="/list" className='btn_detail btn btn-outline-light'>View Details</Link>
                              </div>
                          </Link>
                      </Col>

                  </Row>
              </Container>
          </div>
    </React.Fragment>
  )
}