import React from 'react'
import { Breadcrumb, Button, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Contact() {
    return (
        <React.Fragment>
            <div className='page_heaer'>
                <Container>
                    <h1 className='f_title'>Contact us</h1>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Contact us</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>
            <div className='page_inn'>
                <Container>
                    <Row>
                        <Col md="7">
                            <Form>
                                <Row>
                                    <Form.Group className="mb-3 col-md-6" controlId="name">
                                        <Form.Control type="text" placeholder="Enter Name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-md-6" controlId="mobile">
                                        <Form.Control type="text" placeholder="Enter Mobile no." />
                                    </Form.Group>
                                </Row>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control  type="email" placeholder="Enter email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" placeholder='Message' rows={5} />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}