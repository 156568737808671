import React, { useState } from 'react'
import { Container, Nav, Navbar, Offcanvas, } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import facebook from '../assets/images/facebook.png';
import twitter from '../assets/images/twitter.png';
import linkedin from '../assets/images/linkedin.png';
import youtube from '../assets/images/youtube.png';
import instagram from '../assets/images/instagram.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function Header() {
      const [menushow, setMenushow] = useState(false);
  const handleMenuclose = () => setMenushow(false);
  const handleMenushow = () => setMenushow(true);
  return (
      <React.Fragment>
          <div className='head_top'>
              <Container>
                  <ul>
                      <li><FontAwesomeIcon icon="fa-solid fa-envelope-open-text" /> centraltoursandtravels@gmail.com</li>
                      <li><FontAwesomeIcon icon="fa-solid fa-phone-volume" /> +91 9876543210</li>
                  </ul>
              </Container>
          </div>
          <Navbar key="lg" expand="lg" className="navbar-light bg-light menu_style">
              <Container>
                  <Navbar.Brand as={NavLink} to="/"><img src={logo} alt='' /></Navbar.Brand>
                  <Navbar.Toggle aria-controls={'offcanvasNavbarheader'} onClick={handleMenushow} />
                  <Navbar.Offcanvas
                      id={'offcanvasNavbarheader'}
                      aria-labelledby={'offcanvasNavbarLheader'}
                      placement="start"
                      show={menushow}
                      onHide={handleMenuclose}
                      className="main_menu_off"
                  >
                      <Offcanvas.Header closeButton>
                          <Offcanvas.Title id={'offcanvasNavbarLheader'}>
                              <img src={logo} alt='' />
                          </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                          <Nav className="justify-content-end flex-grow-1">
                              <Nav.Link onClick={handleMenuclose} as={NavLink} to="/">Home</Nav.Link>
                              <Nav.Link onClick={handleMenuclose} as={NavLink} to="/about-us">About Us</Nav.Link>
                              <Nav.Link onClick={handleMenuclose} as={NavLink} to="/packages">Packages</Nav.Link>
                              <Nav.Link onClick={handleMenuclose} as={NavLink} to="/testimonials">Testimonials</Nav.Link>
                              <Nav.Link onClick={handleMenuclose} as={NavLink} to="/photo-gallery">Gallery</Nav.Link>
                              <Nav.Link onClick={handleMenuclose} as={NavLink} to="/car-rentals">Car Rentals</Nav.Link>
                              <Nav.Link onClick={handleMenuclose} as={NavLink} to="/contact-us">Contact us</Nav.Link>
                          </Nav>
                      </Offcanvas.Body>
                      <div className='menu_footer'>
                          <h5>Follow us</h5>
                          <div className='d-flex gap-2'>
                              <Link to="#"><img src={facebook} alt='' /></Link>
                              <Link to="#"><img src={youtube} alt='' /></Link>
                              <Link to="#"><img src={twitter} alt='' /></Link>
                              <Link to="#"><img src={linkedin} alt='' /></Link>
                              <Link to="#"><img src={instagram} alt='' /></Link>
                          </div>
                      </div>
                  </Navbar.Offcanvas>
              </Container>
          </Navbar>
    </React.Fragment>
  )
}