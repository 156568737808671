import React from 'react'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import trouimg from '../assets/images/t1.jpg'
import trouimg1 from '../assets/images/t2.jpg'
import trouimg2 from '../assets/images/t3.jpg'
import trouimg3 from '../assets/images/t4.png'

export default function PackagesList() {
  return (
    <React.Fragment>
          <div className='page_heaer'>
              <Container>
                  <h1 className='f_title'>Packages List</h1>
                  <Breadcrumb>
                      <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                      <Breadcrumb.Item active>Packages List</Breadcrumb.Item>
                  </Breadcrumb>
              </Container>
          </div>
          <div className='tour_packages py-4'>
              <Container> 
                  <Row>
                      <Col md="3" className='mb-4 col-6'>
                          <Link to="/details" className="card_items rounded-2 h-100 h-100">
                              <div className='card__image1'>
                                  <img src={trouimg} className="card__image rounded-2" alt="" />
                              </div>
                              <div className="card_items_inn">
                                  <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                  <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  <p>You're in control, with free cancellation and payment options to...</p>
                                  <div className="d-flex justify-content-between card_footer border-top pt-2">
                                      <span><i className="bi bi-stopwatch"></i> 4 days</span>
                                      <span className='text-danger'>From ₹2025</span>
                                  </div>
                              </div>
                          </Link>
                      </Col>
                      <Col md="3" className='mb-4 col-6'>
                          <Link to="/details" className="card_items rounded-2 h-100">
                              <div className='card__image1'>
                                  <img src={trouimg1} className="card__image rounded-2" alt="" />
                              </div>
                              <div className="card_items_inn">
                                  <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                  <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  <p>You're in control, with free cancellation and payment options to...</p>
                                  <div className="d-flex justify-content-between card_footer border-top pt-2">
                                      <span><i className="bi bi-stopwatch"></i> 4 days</span>
                                      <span className='text-danger'>From ₹2025</span>
                                  </div>
                              </div>
                          </Link>
                      </Col>
                      <Col md="3" className='mb-4 col-6'>
                          <Link to="/details" className="card_items rounded-2 h-100">
                              <div className='card__image1'>
                                  <img src={trouimg2} className="card__image rounded-2" alt="" />
                              </div>
                              <div className="card_items_inn">
                                  <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                  <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  <p>You're in control, with free cancellation and payment options to...</p>
                                  <div className="d-flex justify-content-between card_footer border-top pt-2">
                                      <span><i className="bi bi-stopwatch"></i> 4 days</span>
                                      <span className='text-danger'>From ₹2025</span>
                                  </div>
                              </div>
                          </Link>
                      </Col>
                      <Col md="3" className='mb-4 col-6'>
                          <Link to="/details" className="card_items rounded-2 h-100">
                              <div className='card__image1'>
                                  <img src={trouimg3} className="card__image rounded-2" alt="" />
                              </div>
                              <div className="card_items_inn">
                                  <span className='text-light-2'><i className="bi bi-geo-alt"></i> Paris, France</span>
                                  <h4 className="card_title">Clear Kayak Tour of Shell Key Preserve and Tampa Bay Area</h4>
                                  <p>You're in control, with free cancellation and payment options to...</p>
                                  <div className="d-flex justify-content-between card_footer border-top pt-2">
                                      <span><i className="bi bi-stopwatch"></i> 4 days</span>
                                      <span className='text-danger'>From ₹2025</span>
                                  </div>
                              </div>
                          </Link>
                      </Col>
                  </Row>
              </Container>
          </div>
    </React.Fragment>
  )
}