import React, { useContext } from 'react';
import { Accordion, AccordionContext, Breadcrumb, Button, Card, Col, Container, FloatingLabel, Form, Row, useAccordionButton, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import trouimg from '../assets/images/1.jpg'
import { SlideshowLightbox } from 'lightbox.js-react'
import gallery from '../assets/images/t1.jpg';
import gallery2 from '../assets/images/t2.jpg';
import gallery3 from '../assets/images/t3.jpg';
import gallery4 from '../assets/images/t4.png';
export default function Details() {

    function ContextAwareToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isActive = activeEventKey === eventKey;

        return (
            <div
                onClick={decoratedOnClick}
                aria-expanded={isActive}
                aria-controls={`collapse-${eventKey}`}
                className='title_style_acc'
            >
                {children}
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className='page_heaer'>
                <Container>
                    <h1 className='f_title'>Details</h1>
                    <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/list">Tours </Link></Breadcrumb.Item>
                        <Breadcrumb.Item active>Details</Breadcrumb.Item>
                    </Breadcrumb>
                </Container>
            </div>
            <div className='tour_packages2 py-4'>
                <Container>
                    <div className='py-3'>
                        <h1>All Inclusive Ultimate Circle Island Day Tour with Lunch</h1>
                        <div><i className="bi bi-geo-alt"></i> New York, USA</div>
                    </div>
                    <Row>
                        <Col md="8">
                            <div>
                                <img src={trouimg} alt='' className='mb-4 img-fluid' />
                                <div className='py-2'>
                                    <h3>Tour Overview</h3>
                                    <p>The Phi Phi archipelago is a must-visit while in Phuket, and this speedboat trip whisks you around the islands in one day. Swim over the coral reefs of Pileh Lagoon, have lunch at Phi Phi Leh, snorkel at Bamboo Island, and visit Monkey Beach and Maya Bay, immortalized in "The Beach." Boat transfers, snacks, buffet lunch, snorkeling equipment, and Phuket hotel pickup and drop-off all included.</p>
                                </div>
                                <div className='py-2'>
                                    <h4>Tour Highlights</h4>
                                    <ul>
                                        <li>Experience the thrill of a speedboat to the stunning Phi Phi Islands</li>
                                        <li>Be amazed by the variety of marine life in the archepelago</li>
                                        <li> Enjoy relaxing in paradise with white sand beaches and azure turquoise water</li>
                                        <li>Feel the comfort of a tour limited to 35 passengers</li>
                                        <li> Catch a glimpse of the wild monkeys around Monkey Beach</li>
                                    </ul>
                                </div>
                                <div>
                                    <h3 className='mb-2'>Itinerary</h3>
                                    <Accordion defaultActiveKey="0" className='tour_itinerary mt-3' >
                                        <div className='tour_i_inn'>
                                            <ContextAwareToggle eventKey="0">Day 1: Airport Pick Up </ContextAwareToggle>
                                            <Accordion.Collapse eventKey="0" className='px-3'>
                                                <Card.Body>Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.</Card.Body>
                                            </Accordion.Collapse>
                                        </div>
                                        <div className='tour_i_inn'>
                                            <ContextAwareToggle eventKey="1">Day 2: Temples & River Cruise</ContextAwareToggle>
                                            <Accordion.Collapse eventKey="1" className='px-3'>
                                                <Card.Body>Hello! I am the body Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.</Card.Body>
                                            </Accordion.Collapse>
                                        </div>
                                        <div className='tour_i_inn'>
                                            <ContextAwareToggle eventKey="3">Day 3: Massage & Overnight Train</ContextAwareToggle>
                                            <Accordion.Collapse eventKey="3" className='px-3'>
                                                <Card.Body>Hello! Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.</Card.Body>
                                            </Accordion.Collapse>
                                        </div>
                                        <div className='tour_i_inn'>
                                            <ContextAwareToggle eventKey="4">Day 4: Khao Sok National Park</ContextAwareToggle>
                                            <Accordion.Collapse eventKey="4" className='px-3'>
                                                <Card.Body>Hello! I am Like on all of our trips, we can collect you from the airport when you land and take you directly to your hotel. The first Day is just a check-in Day so you have this freedom to explore the city and get settled in.</Card.Body>
                                            </Accordion.Collapse>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h4 className='mb-3'>Enquiry Form</h4>
                                <form>
                                <Row>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Subject"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Name"
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="" />
                                        </FloatingLabel>
                                    </Col>
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Mobile No."
                                            className="mb-3"
                                        >
                                            <Form.Control type="text" placeholder="" />
                                        </FloatingLabel>
                                    </Col>
                                   
                                    <Col md="6">
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Email address"
                                            className="mb-3"
                                        >
                                            <Form.Control type="email" placeholder="name@example.com" />
                                        </FloatingLabel>
                                    </Col>
                                </Row>
                                <FloatingLabel controlId="floatingTextarea2" label="Comments">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Leave a comment here"
                                        style={{ height: '100px' }}
                                    />
                                    </FloatingLabel>
                                    <Button className='primary-btn3 mt-4'>Submit</Button>
                                </form>
                            </div>
                        </Col>
                        <Col md="4">
                            <SlideshowLightbox className='row row-cols-2 row-cols-md-2 row-cols-lg-2 gallery_img'>
                                <img className="img-fluid mb-3 rounded" src={gallery} alt='' />
                                <img className="img-fluid mb-3 rounded" src={gallery2} alt='' />
                                <img className="img-fluid mb-3 rounded" src={gallery3} alt='' />
                                <img className="img-fluid mb-3 rounded" src={gallery4} alt='' />
                                <img className="img-fluid mb-3 rounded" src={gallery2} alt='' />
                                <img className="img-fluid mb-3 rounded" src={gallery3} alt='' />
                            </SlideshowLightbox>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}