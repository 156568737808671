import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap'
import banner1 from '../assets/images/1.jpg';
import banner2 from '../assets/images/2.jpg';
import banner3 from '../assets/images/3.jpg';
export default function Banner() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    return (
        <div className='banner_style'>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <img src={banner1} alt='' />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={banner2} alt='' />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={banner3} alt='' />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}