import React from 'react'
import about from '../assets/images/about.webp'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function AboutHome() {
  return (
    <div className='page_about'>
          <Container>
              <Row className='align-items-center'>
                  <Col md="6" className='order-lg-2 mb-4 mb-lg-0'>
                      <div className='about-img-bx'>
                          <div className='about-img'>
                              <img src={about} alt='' />
                          </div>
                      </div>
                  </Col>
                  <Col md="6">
                      <div>
                          <h1>Welcome to <br /><strong>Company name</strong></h1>
                        <p>Company name, based in the vibrant city of Varanasi, is your premier destination for seamless travel experiences. With a commitment to excellence and a passion for exploration, we specialize in crafting unforgettable journeys for our valued clients.</p>
                        <p>Our mission is simple: to provide unparalleled service and create tailor-made itineraries that exceed expectations. Whether you're a seasoned globetrotter or embarking on your very first adventure, our team of dedicated travel experts is here to ensure every detail of your trip is meticulously planned and flawlessly executed.</p>
                      </div>
                      <ul className='about_list'>
                          <li><span><FontAwesomeIcon icon="fa-solid fa-person-circle-plus" /></span> Safety First Always</li>
                          <li><span><FontAwesomeIcon icon="fa-solid fa-house-medical-flag" /></span> Trusted Travel Guide</li>
                          <li><span><FontAwesomeIcon icon="fa-solid fa-users-viewfinder" /></span> Expertise And Experience</li>
                          <li><span><FontAwesomeIcon icon="fa-solid fa-calendar-days" /></span> Time And Stress Savings</li>
                      </ul>
                      <Link className='primary-btn3 mt-4'>Read More</Link>
                  </Col>
                  
        </Row>

      </Container>
    </div>
  )
}