// import logo from './logo.svg';

import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './common/Header';
import SwitchColor from './common/SwitchColor';
import Home from './pages/Home';
import Footer from './common/Footer';
import AboutPage from './pages/AboutPage';
import Contact from './pages/Contact';
import Packages from './pages/Packages';
import Details from './pages/Details';
import PackagesList from './pages/PackagesList';

function App() {

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact={"true"} path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/packages" element={<Packages />} />
        {/*<Route path="/photo-gallery" element={<PhotoGallery />} />*/}
        <Route path="/contact-us" element={<Contact />} />
       <Route path="/details" element={<Details />} />
        <Route path="/list" element={<PackagesList />} />
         {/* <Route path="/banquet-hall" element={<BanquetHall />} />
        <Route path="/places-to-visit" element={<ToursPage />} />  */}
      </Routes>
      <SwitchColor />
      <Footer />
    </div>
  );
}

export default App;
