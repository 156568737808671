import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
export default function SwitchColor() {
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
        // Check local storage for dark mode preference on component mount
        const isDarkMode = localStorage.getItem('darkMode') === 'true';
        setIsChecked(isDarkMode);
        if (isDarkMode) {
            document.body.classList.add('dark');
        }
    }, []);
    const handleChange = () => {
        const newState = !isChecked;
        setIsChecked(newState);
        localStorage.setItem('darkMode', newState); // Store dark mode preference in local storage
        document.body.classList.toggle('dark', newState); // Toggle dark mode class on body
    };
    return (
        <React.Fragment>
            <div className='position-fixed' style={{bottom:10, right:10, zIndex:999}}>
            <input
                type="checkbox"
                className="checkbox"
                id="checkbox"
                checked={isChecked}
                onChange={handleChange}
            />
            <label htmlFor="checkbox" className="checkbox-label">
                <FontAwesomeIcon icon={faMoon} className="moon-icon" />
                <FontAwesomeIcon icon={faSun} className="sun-icon" />
                <span className="ball"></span>
                </label>
            </div>
        </React.Fragment>
    )
}